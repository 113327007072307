<template>
  <div class="contact">
    <section class="contact__wrapper">
      <span class="contact__background"></span>

      <div class="container contact__container">
        <img src="/assets/images/dots--big-white.svg" alt=""
             class="contact__dots"/>

        <section class="contact__header">
          <base-sub-heading class="contact__header-sub-heading" align="left">
            <span v-html="$t('contact.subTitle')"/>
          </base-sub-heading>

          <base-heading class="contact__header-heading" align="left">
            <span v-html="$t('contact.title')"/>
          </base-heading>
        </section>

        <validation-observer slim v-slot="{ handleSubmit, reset }">
          <form class="contact__form"
                @submit.prevent="handleSubmit(() => onSubmit(reset))">
<!--            <h2 class="contact__form-title">{{ $t("contact.form.title") }}</h2>-->

            <div class="contact__email">
              <h3 class="contact__social-header">{{ $t("contact.email") }}</h3>
              <a href="mailto:info@edunails.com" class="contact__email-text">
                <img src="/assets/icons/mail.svg" alt="Email"
                     class="contact__email-icon"/>
                info@edunails.com
              </a>
            </div>

            <div class="contact__socials" style="margin-top: 50px;">
              <h3 class="contact__social-header">{{ $t("contact.social") }}</h3>
              <div class="contact__social-icons">
                <a
                  href="https://www.facebook.com/Edunailsofficial-101086191589499/"
                  target="_blank" rel="noreferrer noopener"
                  class="contact__social-icon">
                  <img src="/assets/socials/fb.svg" alt="Facebook"
                       title="Facebook"/>
                </a>
                <a href="https://www.instagram.com/edunails.official/"
                   target="_blank" rel="noreferrer noopener"
                   class="contact__social-icon">
                  <img src="/assets/socials/ig.svg" alt="Instagram"
                       title="Instagram"/>
                </a>
              </div>
            </div>

<!--            <base-input-->
<!--              class="contact__input"-->
<!--              v-model="form.name"-->
<!--              :label="$t('contact.form.name.label')"-->
<!--              rules="required"-->
<!--              :name="$t('contact.form.name.label')"-->
<!--            />-->

<!--            <base-input-->
<!--              class="contact__input"-->
<!--              v-model="form.email"-->
<!--              :label="$t('contact.form.email.label')"-->
<!--              rules="required|email"-->
<!--              :name="$t('contact.form.email.label')"-->
<!--            />-->

<!--            <base-input-->
<!--              class="contact__input"-->
<!--              v-model="form.subject"-->
<!--              :label="$t('contact.form.subject.label')"-->
<!--              rules="required"-->
<!--              :name="$t('contact.form.subject.label')"-->
<!--            />-->

<!--            <base-textarea-->
<!--              class="contact__textarea"-->
<!--              v-model="form.message"-->
<!--              :label="$t('contact.form.message.label')"-->
<!--              :placeholder="$t('contact.form.message.placeholder')"-->
<!--              rules="required"-->
<!--              :name="$t('contact.form.message.label')"-->
<!--            />-->

<!--            <base-button-->
<!--              nativeType="submit"-->
<!--              class="contact__submit"-->
<!--              :is-loading="isLoading"-->
<!--            >-->
<!--              {{ $t("contact.form.submit") }}-->
<!--            </base-button>-->
          </form>
        </validation-observer>

<!--        <section class="contact__social">-->
<!--          <div class="contact__email">-->
<!--            <h3 class="contact__social-header">{{ $t("contact.email") }}</h3>-->
<!--            <a href="mailto:info@ednails.com" class="contact__email-text">-->
<!--              <img src="/assets/icons/mail.svg" alt="Email"-->
<!--                   class="contact__email-icon"/>-->
<!--              info@edunails.com-->
<!--            </a>-->
<!--          </div>-->

<!--          <div class="contact__socials">-->
<!--            <h3 class="contact__social-header">{{ $t("contact.social") }}</h3>-->
<!--            <div class="contact__social-icons">-->
<!--              <a-->
<!--                href="https://www.facebook.com/Edunailsofficial-101086191589499/"-->
<!--                target="_blank" rel="noreferrer noopener"-->
<!--                class="contact__social-icon">-->
<!--                <img src="/assets/socials/fb.svg" alt="Facebook"-->
<!--                     title="Facebook"/>-->
<!--              </a>-->
<!--              <a href="https://www.instagram.com/edunails.official/"-->
<!--                 target="_blank" rel="noreferrer noopener"-->
<!--                 class="contact__social-icon">-->
<!--                <img src="/assets/socials/ig.svg" alt="Instagram"-->
<!--                     title="Instagram"/>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </section>-->
      </div>
    </section>
  </div>
</template>

<script>
import support from '@/api/services/support';

export default {
  name: 'Contact',
  metaInfo: {
    title: 'Edunails - Contact Us',
    titleTemplate: null,
    meta: [
      {
        vmid: 'description',
        name: 'description',
        content: 'Got a query about our online nail art courses, want to become our Nail Instructor or need general support? Get in touch, we’re here to help!',
      },
    ],
  },
  data: () => ({
    form: {
      name: '',
      email: '',
      message: '',
      subject: '',
    },
    isSent: false,
    isBannerClosed: false,
    emailCopy: '',
    isLoading: false,
  }),
  methods: {
    onSubmit(reset) {
      this.sendRequest();
      reset();
      this.isSent = true;
      this.isLoading = true;

      this.emailCopy = this.form.email;
      this.form.name = '';
      this.form.email = '';
      this.form.message = '';
      this.form.subject = '';
    },
    async sendRequest() {
      const courseSlug = this.$route.query.course_slug ?? '';
      const ticket = {
        request: {
          requester: {
            name: this.form.name,
            email: this.form.email.toLowerCase(),
          },
          subject: courseSlug.length > 0 ? `${this.form.subject} (course: https://edunails.com/courses/${courseSlug})` : this.form.subject,
          comment: {
            body: this.form.message,
          },
        },
      };

      try {
        await support.sendTicket(ticket);
        this.$store.dispatch('notification/spawn', {
          type: 'success',
          title: this.$t('apiHandler.success.title'),
          text: `${this.$t('contact.confirmation')} - ${this.emailCopy}`,
        });
      } catch (err) {
        this.$store.dispatch('notification/spawn', {
          errorMessage: err.response.data.message,
          type: 'error',
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped src="./Contact.scss"/>
